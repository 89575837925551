import { Routes, Route } from 'react-router-dom'

// Routes
import Home from './assets/routes/Home'
import WorkWithUs from './assets/routes/WorkWithUs'
import Footer from './assets/components/Footer'

function App () {
  return (
    <div className='flex flex-col bg-slate-50 w-full h-screen text-center'>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/trabaja-con-nosotros' element={<WorkWithUs />} />
      </Routes>
      <Footer />
    </div>
  )
}

export default App
