import { Link } from 'react-router-dom'
// Components
import Header from '../components/Header'
import Contact from '../components/Contact'
// Icons
import { FaFacebook } from 'react-icons/fa'
import { BsLinkedin } from 'react-icons/bs'
// Images
import bgwork from '../files/bg-work.jpeg'

const Home = () => {
  const Interview = () => (
    <div
      className='w-full bg-cover bg-center grid justify-items-center'
      style={{ backgroundImage: `url(${bgwork})` }}
    >
      <div className='grid sm:md:grid-cols-2 grid-cols-1 gap-4 mx-auto container'>
        <div>
          <br />
        </div>
        <div className='py-10 my-10 rounded sm:md:text-left text-center grid justify-items-center sm:md:bg-transparent backdrop-blur-xl'>
          <p className='text-blue-900 capitalize text-4xl font-bold py-2'>
            ¿Te interesa trabajar con nosotros?
          </p>
          <br />
          <p className='text-slate-600 text-xl'>
            Conoce las vacantes que tenemos disponibles para ti
          </p>
          <Link to='/trabaja-con-nosotros'>
            <button className='text-white bg-blue-900 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-9 py-2.5 mr-2 mt-6 mb-2 dark:bg-blue-900 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Ver vacantes</button>
          </Link>
        </div>
      </div>
      <div className='w-full bg-blue-900'>
        <div className='grid justify-items-center p-2'>
          <div className='flex items-center'>
            <a href='https://www.facebook.com/dmgroup.mx'><FaFacebook className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-blue-900 hover:bg-white text-white bg-blue-900 focus:outline-none focus:ring-6 focus:ring-blue-300 rounded-full dark:focus:ring-blue-800' /></a>
            <a href='linkedin.com/company/dm1group'><BsLinkedin className='hover:text-7xl text-6xl m-5 hover:animate-bounce hover:text-blue-900 hover:bg-white text-white bg-blue-900 focus:outline-none focus:ring-6 focus:ring-blue-300 rounded-full dark:focus:ring-blue-800' /></a>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      <Header text='Somos un grupo de empresas Mexicanas, dedicadas a brindar el máximo valor agregado, con calidad, servicio y dedicación para satisfacer las necesidades de nuestros clientes ' />
      <Interview />
      <Contact />
    </div>
  )
}

export default Home

// https://buy.stripe.com/dR600v50Na33eA07sL
