import bgcity from '../files/bg-city.jpeg'
import logo from '../files/logo-dm.png'
import contel from '../files/contel.svg'
import inversol from '../files/inversol.svg'
import worldbrands from '../files/world-brands.svg'
import dminmobiliaria from '../files/dm-inmobiliaria.png'
import adseguros from '../files/ad-seguros.svg'

const Header = ({ isTrue = true, text = '', title = '' }) => {
  return (
    <header>
      <div
        className='w-full bg-cover bg-center grid justify-items-center'
        style={{ backgroundImage: `url(${bgcity})` }}
      >
        <div className='flex items-center justify-center h-full w-full'>
          <div className='grid justify-items-center sm:md:m-10 sm:md:p-10 m-2 p-2 container'>
            <img src={logo} alt='logo' className='w-2/3 sm:md:m-10 sm:md:p-10 m-2 p-2' />
            {title.length > 0 && (<p className='text-blue-900 sm:md:text-5xl text-xl font-bold sm:md:mx-10 sm:md:px-10 mx-2 px-2 py-2'>{title}</p>)}
            {text.length > 0 && (<p className='text-slate-500 sm:md:text-2xl text-sm font-medium sm:md:mx-10 sm:md:px-10 mx-2 px-2'>{text}</p>)}
          </div>
        </div>
        {isTrue && (
          <div className='grid sm:grid-cols-5 grid-cols-2 justify-items-center sm:w-full w-2/3 sm:bg-white sm:px-0 px-10 mb-20 bg-slate-50 rounded'>
            <a className='sm:col-span-1 col-span-2 ' href='https://worldbrands.com.mx/'><img src={worldbrands} alt='logo' className='m-2 p-2 w-full h-full' /></a>
            <a href='http://segcontel.com.mx/'><img src={contel} alt='logo' className='m-2 p-2 w-full h-full' /></a>
            <a href='http://adseguros.com.mx/'><img src={adseguros} alt='logo' className='mx-2 p-2 w-full h-full' /></a>
            <a href='https://dminmobiliaria.com.mx/'><img src={dminmobiliaria} alt='logo' className='p-8 w-full h-full' /></a>
            <img src={inversol} alt='logo' className='m-2 p-2' />
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
