// Components
import Header from '../components/Header'
import FromContact from '../components/FromContact'
// Logos
import contel from '../files/contel.svg'
import inversol from '../files/inversol.svg'
import worldbrands from '../files/world-brands.svg'
import adseguros from '../files/ad-seguros.svg'
// BG
import bgwork from '../files/wrk-copy.jpeg'

const WorkWithUs = () => {
  return (
    <div>
      <Header isTrue={false} text='Conoce las oportunidades que tenemos para tí. Aquí encontrarás un listado actualizado con las vacantes disponibles en nuestras diferentes empresas.' title='Trabaja con nosotros' />
      <div
        className='w-full bg-cover bg-no-repeat bg-fixed grid justify-items-center'
        style={{ backgroundImage: `url(${bgwork})` }}
      >
        <div className='grid grid-cols-2 gap-1 container mx-auto'>
          <div> </div>
          <div className='sm:md:col-span-1 col-span-2 grid sm:md:grid-cols-2 grid-cols-1 gap-4 container mx-auto'>
            <div className='px-2 my-10 bg-white mx-auto shadow-zinc-400 shadow-inner'>
              <img src={inversol} alt='logo' className='w-full' />
              <p className='text-xl font-medium text-gray-700'>Vacantes</p>
              <p className='text-md font-light text-gray-900 pt-2'>Vendedor Sr.</p>
              <p className='text-md font-light text-gray-900 pt-2'>Vendedores comisionistas</p>
              <p className='text-md font-light text-gray-900 pt-2 mb-6'>Instalador Certificado</p>
            </div>
            <div className='px-2 my-10  bg-white mx-auto shadow-zinc-400 shadow-inner'>
              <img src={contel} alt='logo' className='w-full' />
              <p className='text-xl font-medium text-gray-700'>Vacantes</p>
              <p className='text-md font-light text-gray-900 pt-2'>Elementos de seguridad intramuros</p>
              <p className='text-md font-light text-gray-900 pt-2'>Vendedores comisionistas</p>
              <p className='text-md font-light text-gray-900 pt-2 mb-6'>Instaladores de CCTV</p>
            </div>
            <div className='px-2 my-10 bg-white mx-auto shadow-zinc-400 shadow-inner'>
              <img src={worldbrands} alt='logo' className='w-full h-1/2' />
              <p className='text-xl font-medium text-gray-700'>Vacantes</p>
              <p className='text-md font-light text-gray-900 pt-2'>KAM - Retail</p>
              <p className='text-md font-light text-gray-900 pt-2 mb-6'>KAM - Mayoreo</p>
            </div>
            <div className='px-2 my-10 bg-white mx-auto shadow-zinc-400 shadow-inner'>
              <img src={adseguros} alt='logo' className='w-full h-1/2' />
              <p className='text-xl font-medium text-gray-700'>Vacantes</p>
              <p className='text-md font-light text-gray-900 pt-2 mb-6'>Asesores de seguros</p>
            </div>
          </div>
          <div> </div>
          <div className='sm:md:col-span-1 col-span-2 py-10 my-10 sm:md:text-left text-center grid justify-items-center rounded sm:md:bg-transparent backdrop-blur-xl'>
            <FromContact />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WorkWithUs
